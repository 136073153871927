import "./src/styles/global.css";
import Layout from "layout/layout";
import ArticleLayout from "layout/articleLayout";
import * as React from "react";

export const wrapPageElement = ({ element, props }) => {
    if (props.pageContext.layout === "ARTICLE_NAV") {
        return (
            <Layout {...props}>
                <ArticleLayout pageContext={props.pageContext}>
                    {element}
                </ArticleLayout>
            </Layout>
        );
    }
    return <Layout {...props}>{element}</Layout>;
};
